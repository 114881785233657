import { instances } from '../core'

function requestSuccess (config) {
  // TODO: add logic
  return config
}

function requestError (error) {
  // TODO: add logic
  return error
}

function responseSuccess (response) {
  // TODO: add logic
  // throw new axios.Cancel('Operation canceled by the user.')
  return response
}

function responseError (error) {
  // TODO: add logic
  return error
}

Object.values(instances)
  .forEach(i => {
    i.interceptors.request.use(
      requestSuccess,
      requestError,
      { synchronous: true }
    )

    i.interceptors.response.use(
      responseSuccess,
      responseError,
      { synchronous: true }
    )
  })
