import { makeIcon } from 'bootstrap-vue/src/icons/helpers/make-icon'
import string from '@/assets/js/helper/string'

function createCustomIcons (context) {
  return context
    .keys()
    .reduce((exports, key) => {
      const name = string.pascalCase(key.replace(/(^.\/|.svg$)/g, ''))
      const path = context(key).default.match(/<path[^/>]+\/>/g).join('\n')
      const icon = makeIcon(name, path)

      return Object.assign(exports, { [icon.extendOptions.name]: icon })
    }, {})
}

export default createCustomIcons(require.context('@/../public/icons', true, /.\.svg$/))
