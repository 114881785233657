import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP, TOASTER_OPTIONS } from '@/constants'

import Vue from 'vue'
import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import ENTITY from '../base/entity'

export default {
  namespaced: true,
  state: base.createState(IS.state, ENTITY.state, {
    invitations: []
  }),
  getters: base.createGetters(IS.getters, ENTITY.getters, {
    getInvitations: state => state.invitations,
    getInvitation: state => invitationId => state.invitations.find(i => i.id === invitationId)
  }),
  mutations: base.createMutations(IS.mutations, ENTITY.mutations, {
    setInvitations (state, invitations = []) {
      state.invitations = invitations
    },
    setInvitation (state, invitation = {}) {
      const updateIndex = state.invitations.findIndex(i => i.id === invitation.id)
      const newIndex = state.invitations.length

      Vue.set(state.invitations, updateIndex >= 0 ? updateIndex : newIndex, invitation)
    },
    removeInvitation (state, invitation = {}) {
      const removeIndex = state.invitations.findIndex(i => i.id === invitation.id)

      Vue.delete(state.invitations, removeIndex)
    }
  }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.customer, STOREMODULE_ENDPOINT_MAP.userlocations, IS.actions, ENTITY.actions, {
    getAll (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const entityGetAllAction = ENTITY.actions.getAll(serverModuleName, serverEntityName, entityKey)

      return function (store) {
        return entityGetAllAction(...arguments)
          .then(response => {
            store.dispatch('getRelationEntities')
            return response
          })
      }
    },
    get (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const entityGetAction = ENTITY.actions.get(serverModuleName, serverEntityName, entityKey)

      return function (store) {
        return entityGetAction(...arguments)
          .then(response => {
            store.dispatch('getRelationEntities')
            return response
          })
      }
    },
    getRelationEntities (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch, rootGetters }) {
        const existingUserIds = rootGetters['users/getUnwrapped'].map(u => u.id)
        const userIds = getters.getUnwrapped.filter(e => !existingUserIds.includes(e.userId)).map(e => e.userId)

        const existingLocationIds = rootGetters['locations/getUnwrapped'].map(l => l.id)
        const locationIds = getters.getUnwrapped.filter(e => !existingLocationIds.includes(e.locationId)).map(e => e.locationId)

        dispatch('users/getEntities', userIds, { root: true })
        dispatch('locations/getEntities', locationIds, { root: true })
      }
    },
    edit (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/edit`

      return function ({ state, getters, commit, dispatch }, formData = {}) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/edit`,
            data: formData
          })
            .then(response => {
              commit('set', { key: entityKey, entities: response.data.result })
              resolve(getters.get)
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    },
    getInvitations (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/getInvitations`

      return function ({ state, getters, commit, dispatch }, locationId = '') {
        commit('setLoading', { key: isKey, loading: true, initial: state.invitations.length === 0 })

        return new Promise(resolve => {
          http({
            method: 'get',
            url: `/${serverModuleName}/api/${serverEntityName}/${locationId}/invites`
          })
            .then(response => {
              commit('setInvitations', response.data.result)
              resolve(getters.getInvitations)
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    createInvitation (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/createInvitation`

      return function ({ state, getters, commit, dispatch }, options = {}) {
        commit('setSending', { key: isKey, sending: false })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/${options.locationId}/invite`,
            data: options.invite
          })
            .then(response => {
              if (response.data.result.isExistingUser) {
                commit('setEntity', { key: entityKey, entity: response.data.result.userLocation })
                resolve(getters.getEntity(response.data.result.userLocation.id))
                dispatch('getRelationEntities')
              } else {
                commit('setInvitation', response.data.result.userInvitation)
                resolve(getters.getInvitation(response.data.result.userInvitation.id))
              }

              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.invited'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    },
    removeInvitation (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/removeInvitation`

      return function ({ state, getters, commit, dispatch }, options = {}) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'delete',
            url: `/${serverModuleName}/api/${serverEntityName}/${options.locationId}/invitation/${options.invitation.id}`
          })
            .then(response => {
              commit('removeInvitation', options.invitation)
              resolve()
              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.removed'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    },
    reInviteUser (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/reInviteUser`

      return function ({ state, getters, commit, dispatch }, options = {}) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/${options.locationId}/invitation/${options.invitation.id}/reinvite`
          })
            .then(response => {
              resolve()
              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.invited'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    }
  })
}
