import Vue from 'vue'
import App from '@/App.vue'

import router from '@/$plugins/router'
import store from '@/$plugins/store'
import http from '@/$plugins/http'
import auth, { authManagers } from '@/$plugins/auth'
import i18n from '@/$plugins/i18n'
import timeago from '@/$plugins/timeago'
import bootstrap from '@/$plugins/bootstrap'
import vuelidate, { validators } from '@/$plugins/vuelidate'

import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

import MixinViewport from '@/components/mixins/Viewport'

// vue settings
Vue.config.productionTip = false

authManagers.autologin()
  .then(() => {
    // global data fetches
    store.dispatch('validators/get')

    const $app = new Vue({
      router,
      store,
      http,
      auth,
      i18n,
      timeago,
      bootstrap,
      vuelidate,
      mixins: [
        MixinViewport
      ],
      data: {
        env: {
          isDevelopment: process.env.NODE_ENV === 'development',
          isProduction: process.env.NODE_ENV === 'production',
          publicPath: process.env.BASE_URL,
          isTouch: 'ontouchstart' in window || window.navigator.maxTouchPoints > 0 || window.navigator.msMaxTouchPoints > 0
        },
        scss: SCSS_VARIABLES,
        validators
      },
      methods: {
        isNullOrEmpty (value) {
          return value === null || value === undefined || value === '' || value === []
        },
        isBoolean (value) {
          return typeof value === 'boolean'
        }
      },
      render: h => h(App)
    }).$mount('#app')

    Vue.prototype.$app = Vue.$app = Object.assign($app, Vue.$app)
  })
