<template>
  <div :class="['search-filter', 'search-filter-multiselect', { 'is-expanded': expanded }]">
    <div class="filter-header">
      <b-button class="filter-expander" variant="link" block @click="toggleExpanded()">
        <b-icon icon="arrow-dropdown" scale="0.9"/>{{ filter.displayName }} <span v-if="filterSetCount" class="filter-set-count">{{ filterSetCount }}</span>
      </b-button>

      <b-button v-if="expanded" class="filter-search-expander" variant="link" @click="toggleSearchExpanded()"><b-icon icon="search" scale="0.9"/></b-button>
    </div>

    <transition-expand>
      <div v-if="expanded" class="filter-body">
        <div class="filter-controls">
          <div v-show="searchExpanded" class="filter-search-control">
            <control-input
              :id="search.id"
              v-model="search.value"
              :placeholder="search.placeholder"
              size="sm"
              ref="searchControl"
              @input="searchItems"
              @keydown.native.esc="resetSearch"
            />

            <b-button variant="link" size="sm" class="search-control-reset" :disabled="!search.value" @click="resetSearch"><b-icon icon="close" scale="0.8"/></b-button>
          </div>

          <div class="controls-meta">
            <b-form-row class="justify-content-between align-items-center">
              <b-col cols="auto">
                <b-button variant="link" :disabled="allFilterSet" @click="setControls(true)">{{ $t('components.navigation.filters.button.selectall') }}</b-button>
              </b-col>

              <b-col cols="auto">
                <b-button variant="link" :disabled="noneFilterSet" @click="resetControls">{{ $t('components.navigation.filters.button.selectnone') }}</b-button>
              </b-col>
            </b-form-row>
          </div>

          <div
            v-for="control in filter.controls"
            :key="control.id"
            v-show="!control.hide"
            class="controls-control"
          >
            <control-checkbox
              :id="control.id"
              :value="control.value"
              :text="control.text"
              @change="$value => updateControl(control, $value)"
            />

            <div class="control-count">{{ control.count }}</div>
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import MixinFilterBase from '@/components/search/MixinFilterBase'
import MixinFilterSearch from '@/components/search/MixinFilterSearch'
import TransitionExpand from '@/components/extension/TransitionExpand'
import ControlInput from '@/components/form/ControlInput'
import ControlCheckbox from '@/components/form/ControlCheckbox'

export default {
  name: 'Search.FilterMultiselect',
  mixins: [MixinFilterBase, MixinFilterSearch],
  components: {
    TransitionExpand,
    ControlInput,
    ControlCheckbox
  }
}
</script>

<style lang="scss">
.search-filter-multiselect {}
</style>
