<template>
  <transition
    name="expand"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
  >
    <slot/>
  </transition>
</template>

<script>
export default {
  name: 'TransitionExpand',
  props: {
    minHeight: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      state: false,
      singleElement: this.minHeight > 0,
      closeHeight: `${this.minHeight}px`,
      repaintHeight: 0
    }
  },
  methods: {
    beforeEnter (element) {
      this.state = !this.state

      if (this.singleElement) {
        element.classList.remove('expand-state-open', 'expand-state-closed')

        if (!this.state) {
          element.classList.remove('expand-enter')
          element.classList.add('expand-leave')
        }
      }
    },
    enter (element) {
      if (this.singleElement && !this.state) {
        element.classList.remove('expand-enter-active')
        element.classList.add('expand-leave-active')
      }

      const width = getComputedStyle(element).width

      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null

      if (this.singleElement) {
        element.style.height = this.state ? this.closeHeight : height
      } else {
        element.style.height = 0
      }

      // Force repaint to make sure the
      // animation is triggered correctly.
      this.repaintHeight = getComputedStyle(element).height

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        if (this.singleElement && !this.state) {
          element.style.height = this.state ? height : this.closeHeight

          requestAnimationFrame(() => {
            element.classList.remove('expand-enter-to')
            element.classList.add('expand-leave-to')
            element.classList.remove('expand-leave')
          })
        } else {
          element.style.height = height
        }
      })
    },
    afterEnter (element) {
      if (this.singleElement) {
        element.classList.add(`expand-state-${this.state ? 'open' : 'closed'}`)

        if (this.state) {
          element.style.overflow = null
          element.style.height = 'auto'
        } else {
          element.style.overflow = 'hidden'
          element.style.height = this.closeHeight
          element.classList.remove('expand-leave-active', 'expand-leave-to')
        }
      } else {
        element.style.height = 'auto'
      }
    },
    beforeLeave (element) {
      if (this.singleElement) {
        element.style.display = 'none'
      }
    },
    leave (element) {
      const height = getComputedStyle(element).height

      element.style.height = height

      // Force repaint to make sure the
      // animation is triggered correctly.
      this.repaintHeight = getComputedStyle(element).height

      requestAnimationFrame(() => {
        element.style.height = this.closeHeight
      })
    }
  },
  mounted () {
    if (this.singleElement) {
      this.afterEnter(this.$el)
    }
  }
}
</script>

<style lang="scss">
.expand-enter-active,
.expand-leave-active {
  overflow: hidden;
  transition: height ease-out 500ms;
}
</style>
