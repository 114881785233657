import { USER_ROLES } from '@/constants'

export default [
  {
    path: process.env.VUE_APP_USE_LANG_IN_URL === 'true' ? '/:lang([a-zA-Z][a-zA-Z])?' : '',
    component: { render: h => h('router-view') },
    navigationRoutesEntry: true,
    children: [
      {
        path: '',
        redirect: { name: 'Dashboard' }
      },
      { /* Dashboard */
        name: 'Dashboard',
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "v-dashboard" */ '@/views/Dashboard'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'dashboard',
            exact: true
          }
        }
      },
      { /* Locations */
        name: 'Locations',
        path: 'locations',
        component: () => import(/* webpackChunkName: "v-locations" */ '@/views/Locations'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'locations'
          }
        },
        children: [
          { /* Users.Add */
            name: 'Locations.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-locations-add" */ '@/views/Locations/Add')
          },
          { /* Locations.DefaultRedirect */
            path: ':locationid',
            redirect: { name: 'Locations.Detail' }
          },
          { /* Locations.Detail */
            name: 'Locations.Detail',
            path: ':locationid/detail',
            component: () => import(/* webpackChunkName: "v-locations-detail" */ '@/views/Locations/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'location'
              }
            }
          },
          { /* Locations.Addresses */
            name: 'Locations.Addresses',
            path: ':locationid/addresses',
            component: () => import(/* webpackChunkName: "v-locations-addresses" */ '@/views/Locations/Addresses'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'addresses'
              }
            },
            children: [
              { /* Locations.Addresses.Add */
                name: 'Locations.Addresses.Add',
                path: 'add',
                component: () => import(/* webpackChunkName: "v-users-locations-add" */ '@/views/Locations/Addresses/Add')
              },
              { /* Locations.Addresses.Detail */
                name: 'Locations.Addresses.Detail',
                path: ':locationaddressid/detail',
                component: () => import(/* webpackChunkName: "v-locations-addresses-detail" */ '@/views/Locations/Addresses/Detail'),
                meta: {
                  navi: {
                    arrangement: 'start',
                    icon: 'addresses'
                  }
                }
              }
            ]
          },
          { /* Locations.Users */
            name: 'Locations.Users',
            path: ':locationid/users',
            component: () => import(/* webpackChunkName: "v-locations-users" */ '@/views/Locations/Users'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'location-users'
              }
            }
          },
          { /* Locations.Notes */
            name: 'Locations.Notes',
            path: ':locationid/notes',
            component: () => import(/* webpackChunkName: "v-locations-notes" */ '@/views/Locations/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Locations.ChangeTracking */
            name: 'Locations.ChangeTracking',
            path: ':locationid/changetracking',
            component: () => import(/* webpackChunkName: "v-locations-changetracking" */ '@/views/Locations/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* Users */
        name: 'Users',
        path: 'users',
        component: () => import(/* webpackChunkName: "v-users" */ '@/views/Users'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'users'
          }
        },
        children: [
          { /* Users.Add */
            name: 'Users.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-users-add" */ '@/views/Users/Add')
          },
          { /* Users.DefaultRedirect */
            path: ':userid',
            redirect: { name: 'Users.Detail' }
          },
          { /* Users.Detail */
            name: 'Users.Detail',
            path: ':userid/detail',
            component: () => import(/* webpackChunkName: "v-users-detail" */ '@/views/Users/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'user'
              }
            }
          },
          { /* Users.Addresses */
            name: 'Users.Addresses',
            path: ':userid/addresses',
            component: () => import(/* webpackChunkName: "v-users-addresses" */ '@/views/Users/Addresses'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'addresses'
              }
            },
            children: [
              { /* Users.Addresses.Add */
                name: 'Users.Addresses.Add',
                path: 'add',
                component: () => import(/* webpackChunkName: "v-users-addresses-add" */ '@/views/Users/Addresses/Add')
              },
              { /* Users.Addresses.Detail */
                name: 'Users.Addresses.Detail',
                path: ':useraddressid/detail',
                component: () => import(/* webpackChunkName: "v-users-addresses-detail" */ '@/views/Users/Addresses/Detail'),
                meta: {
                  navi: {
                    arrangement: 'start',
                    icon: 'addresses'
                  }
                }
              }
            ]
          },
          { /* Users.Logins */
            name: 'Users.Logins',
            path: ':userid/logins',
            component: () => import(/* webpackChunkName: "v-users-logins" */ '@/views/Users/Logins'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'accounts'
              }
            }
          },
          { /* Users.Services */
            name: 'Users.Services',
            path: ':userid/services',
            component: () => import(/* webpackChunkName: "v-users-services" */ '@/views/Users/Services'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'services'
              }
            }
          },
          { /* Users.Locations */
            name: 'Users.Locations',
            path: ':userid/locations',
            component: () => import(/* webpackChunkName: "v-users-locations" */ '@/views/Users/Locations'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'user-locations'
              }
            }
          },
          { /* Users.Notes */
            name: 'Users.Notes',
            path: ':userid/notes',
            component: () => import(/* webpackChunkName: "v-users-notes" */ '@/views/Users/Notes'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'notes'
              }
            }
          },
          { /* Users.ChangeTracking */
            name: 'Users.ChangeTracking',
            path: ':userid/changetracking',
            component: () => import(/* webpackChunkName: "v-users-changetracking" */ '@/views/Users/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* Orders */
        name: 'Orders',
        path: 'orders',
        component: () => import(/* webpackChunkName: "v-orders" */ '@/views/Orders'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'orders'
          }
        },
        children: [
          { /* Orders.Detail */
            name: 'Orders.Detail',
            path: ':orderid/detail',
            component: () => import(/* webpackChunkName: "v-orders-detail" */ '@/views/Orders/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'orders'
              }
            }
          },
          { /* Orders.VoigtDetail */
            name: 'Orders.VoigtDetail',
            path: ':orderid/voigt_detail',
            component: () => import(/* webpackChunkName: "v-orders-voigtdetail" */ '@/views/Orders/VoigtDetail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'orders'
              }
            }
          },
          { /* Orders.Mails */
            name: 'Orders.Mails',
            path: ':orderid/mails',
            component: () => import(/* webpackChunkName: "v-orders-mails" */ '@/views/Orders/Mails'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'detail'
              }
            }
          },
          { /* Orders.ChangeTracking */
            name: 'Orders.ChangeTracking',
            path: ':orderid/changetracking',
            component: () => import(/* webpackChunkName: "v-orders-changetracking" */ '@/views/Orders/ChangeTracking'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'history'
              }
            }
          }
        ]
      },
      { /* OrderReturns */
        name: 'OrderReturns',
        path: 'orderreturns',
        component: () => import(/* webpackChunkName: "v-orderreturns" */ '@/views/OrderReturns'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'b-arrow-clockwise'
          }
        },
        children: [
          { /* Productpopularities.Detail */
            name: 'OrderReturns.Detail',
            path: ':orderreturnid/detail',
            component: () => import(/* webpackChunkName: "v-orderreturns-detail" */ '@/views/OrderReturns/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'b-arrow-clockwise'
              }
            }
          }
        ]
      },
      { /* Productpopularities */
        name: 'Productpopularities',
        path: 'productpopularity',
        component: () => import(/* webpackChunkName: "v-productpopularities" */ '@/views/ProductPopularities'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'b-bar-chart-line'
          }
        },
        children: [
          { /* Productpopularities.Detail */
            name: 'Productpopularities.Detail',
            path: ':productpopularityid/detail',
            component: () => import(/* webpackChunkName: "v-productpopularities-detail" */ '@/views/ProductPopularities/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'b-bar-chart-line'
              }
            }
          }
        ]
      },
      { /* Vouchers */
        name: 'Vouchers',
        path: 'vouchers',
        component: () => import(/* webpackChunkName: "v-vouchers" */ '@/views/Vouchers'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'b-bookmark-heart'
          }
        },
        children: [
          { /* Vouchers.Add */
            name: 'Vouchers.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-vouchers-add" */ '@/views/Vouchers/Add')
          },
          { /* Vouchers.Detail */
            name: 'Vouchers.Detail',
            path: ':voucherid/detail',
            component: () => import(/* webpackChunkName: "v-vouchers-detail" */ '@/views/Vouchers/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'b-bookmark-heart'
              }
            }
          },
          { /* Vouchers.Discounts */
            name: 'Vouchers.Discounts',
            path: ':voucherid/voucher_discounts',
            component: () => import(/* webpackChunkName: "v-vouchers-discounts" */ '@/views/Vouchers/Discounts'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'b-percent'
              }
            },
            children: [
              { /* Vouchers.Discounts.Add */
                name: 'Vouchers.Discounts.Add',
                path: 'add',
                component: () => import(/* webpackChunkName: "v-vouchers-discounts-add" */ '@/views/Vouchers/Discounts/Add')
              },
              { /* Vouchers.Discounts.Edit */
                name: 'Vouchers.Discounts.Detail',
                path: ':discountid/detail',
                component: () => import(/* webpackChunkName: "v-vouchers-discounts-edit" */ '@/views/Vouchers/Discounts/Detail')
              }
            ]
          }
        ]
      },
      { /* GiftCards */
        name: 'GiftCards',
        path: 'giftcards',
        component: () => import(/* webpackChunkName: "v-giftcards" */ '@/views/GiftCards'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'b-card-heading'
          }
        },
        children: [
          { /* GiftCards.Add */
            name: 'GiftCards.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-giftcards-add" */ '@/views/GiftCards/Add')
          },
          { /* GiftCards.Detail */
            name: 'GiftCards.Detail',
            path: ':giftcardid/detail',
            component: () => import(/* webpackChunkName: "v-giftcards-detail" */ '@/views/GiftCards/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'b-card-heading'
              }
            }
          }
        ]
      },
      { /* GraduateDiscounts */
        name: 'GraduateDiscounts',
        path: 'graduatediscounts',
        component: () => import(/* webpackChunkName: "v-graduatediscounts" */ '@/views/GraduateDiscounts'),
        meta: {
          auth: [USER_ROLES.admin, USER_ROLES.super],
          navi: {
            arrangement: 'start',
            icon: 'b-percent'
          }
        },
        children: [
          { /* GraduateDiscounts.Add */
            name: 'GraduateDiscounts.Add',
            path: 'add',
            component: () => import(/* webpackChunkName: "v-graduatediscounts-add" */ '@/views/GraduateDiscounts/Add')
          },
          { /* GraduateDiscounts.Detail */
            name: 'GraduateDiscounts.Detail',
            path: ':giftcardid/detail',
            component: () => import(/* webpackChunkName: "v-graduatediscounts-detail" */ '@/views/GraduateDiscounts/Detail'),
            meta: {
              navi: {
                arrangement: 'start',
                icon: 'b-percent'
              }
            }
          }
        ]
      },
      { /* Login */
        name: 'Login',
        path: 'auth/login',
        component: () => import(/* webpackChunkName: "v-auth-login" */ '@/views/auth/Login')
      },
      { /* Logout */
        name: 'Logout',
        path: 'auth/logout',
        component: () => import(/* webpackChunkName: "v-auth-logout" */ '@/views/auth/Logout')
      },
      { /* Forbidden (403) */
        name: 'Forbidden',
        path: 'auth/403',
        component: () => import(/* webpackChunkName: "v-auth-forbidden" */ '@/views/auth/Forbidden')
      },
      { /* Notfound (404) */
        name: 'Notfound',
        path: '404',
        component: () => import(/* webpackChunkName: "v-notfound" */ '@/views/Notfound')
      },
      {
        name: 'Refresh',
        path: 'refresh',
        component: {
          render: h => h('div'),
          beforeRouteEnter (to, from, next) {
            next(vm => {
              vm.$router.replace(from.path)
            })
          }
        }
      },
      {
        path: '*',
        redirect: { name: 'Notfound' }
      }
    ]
  }
]
