<template>
  <div></div>
</template>

<script>
import MixinFilterBase from '@/components/search/MixinFilterBase'

export default {
  name: 'Search.FilterGeoPoint',
  mixins: [MixinFilterBase],
  components: {
  },
  data () {
    return {
    }
  },
  methods: {
  },
  created () {
  }
}
</script>

<style lang="scss">
</style>
