import Vue from 'vue'
import Vuex from 'vuex'

import validators from './modules/validators'

import configuration from './modules/configuration'
import gui from './modules/gui'
import profile from './modules/profile'

import changetracking from './modules/change-tracking'
import services from './modules/services'

import locationaddresses from './modules/location-addresses'
import locationevents from './modules/location-events'
import locations from './modules/locations'

import useraddresses from './modules/user-addresses'
import userlocations from './modules/user-locations'
import userlogins from './modules/user-logins'
import userservices from './modules/user-services'
import users from './modules/users'

import orderreturns from './modules/orderreturns'
import orders from './modules/orders'

import productpopularities from './modules/productpopularities'

import giftcards from './modules/giftcards'
import vouchers from './modules/vouchers'

import graduatediscounts from './modules/graduatediscounts'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    configuration,
    gui,
    profile,
    validators,

    changetracking,
    services,

    locations,
    locationaddresses,
    locationevents,

    users,
    useraddresses,
    userlogins,
    userservices,
    userlocations,

    orders,
    orderreturns,

    productpopularities,

    vouchers,
    giftcards,

    graduatediscounts
  }
})
