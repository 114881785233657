import Vue from 'vue'
import VueRouter from 'vue-router'
import qs from 'qs'
import Routes from './routes'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  stringifyQuery: query => {
    const result = qs.stringify(query, { format: 'RFC1738' })
    return result ? `?${result}` : ''
  },
  routes: Routes
})
