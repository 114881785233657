import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP, PAGINATION_INITIAL_VALUES } from '@/constants'

// import Vue from 'vue'
import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    statistics: {
      page: {
        number: PAGINATION_INITIAL_VALUES.page,
        size: PAGINATION_INITIAL_VALUES.pageSize
      },
      total: {
        items: 0,
        pages: 0
      }
    },
    changeTrackings: []
  }),
  getters: base.createGetters(IS.getters, {
    statistics: state => state.statistics,
    get: state => state.changeTrackings
  }),
  mutations: base.createMutations(IS.mutations, {
    setStatistics (state, statistics = {}) {
      state.statistics.page.number = statistics.pageNumber
      state.statistics.page.size = statistics.pageSize
      state.statistics.total.items = statistics.totalItems
      state.statistics.total.pages = statistics.totalPages
    },
    set (state, changeTrackings = []) {
      state.changeTrackings = changeTrackings
    }
  }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.customer, STOREMODULE_ENDPOINT_MAP.changetracking, IS.actions, {
    getAll (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options)

        commit('setLoading', { key: 'getAll', loading: true, initial: state.changeTrackings.length === 0 })

        return new Promise(resolve => {
          http({
            method: 'get',
            url: `/${o.storeModule}/api/${entityKey}${o.by.url.entity}/all`
          })
            .then(response => {
              commit('set', response.data.result)
              resolve(getters.get)
            })
            .finally(() => {
              commit('setLoading', { key: 'getAll', loading: false })
            })
        })
      }
    },
    get (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        const o = base.getOptions(options, {
          page: state.statistics.page.number,
          pageSize: state.statistics.page.size
        })

        commit('setLoading', { key: 'get', loading: true, initial: state.changeTrackings.length === 0 })

        return new Promise(resolve => {
          http({
            method: 'get',
            url: `/${o.storeModule}/api/${entityKey}${o.by.url.entity}`,
            params: o.data
          })
            .then(response => {
              commit('setStatistics', response.data)
              commit('set', response.data.result)
              resolve(getters.get)
            })
            .finally(() => {
              commit('setLoading', { key: 'get', loading: false })
            })
        })
      }
    },
    getPage (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = { storeModule: '', by: {}, data: {}, number: undefined, action: undefined }) {
        if (options.number !== undefined) {
          state.statistics.page.number = options.number
          if (options.action !== false) {
            dispatch(options.action !== undefined ? options.action : 'get', { storeModule: options.storeModule, by: options.by || {}, data: options.data || {} })
          }
        }
      }
    }
  })
}
