import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

// import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import http from '@/$plugins/http'
import CHANGE_REQUESTS from '../base/change-requests'
import SEARCH from '../base/entity-search'
import MAINENTITY from '../base/entity-main'

export default {
  namespaced: true,
  state: base.createState(IS.state, CHANGE_REQUESTS.state, SEARCH.state, MAINENTITY.state, { /* define custom state-properties */ }),
  getters: base.createGetters(IS.getters, CHANGE_REQUESTS.getters, SEARCH.getters, MAINENTITY.getters, { /* define custom getters */ }),
  mutations: base.createMutations(IS.mutations, CHANGE_REQUESTS.mutations, SEARCH.mutations, MAINENTITY.mutations, { /* define custom mutations */ }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.order, STOREMODULE_ENDPOINT_MAP.orders, IS.actions, CHANGE_REQUESTS.actions, SEARCH.actions, MAINENTITY.actions, {
    executeOrder (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/executeOrder`

      return function ({ state, getters, commit, dispatch, rootGetters }, { orderId, configuration }) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/finish/${orderId}/reexecute`,
            data: configuration
          })
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              console.log(error.response.data)
              resolve(error.response.data)
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    setOrderState (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/executeOrder`

      return function ({ state, getters, commit, dispatch, rootGetters }, { orderId, configuration }) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/updatestate/${orderId}`,
            data: configuration
          })
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              console.log(error.response.data)
              resolve(error.response.data)
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    setPartiallyCanceledOrderState (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/executeOrder`

      return function ({ state, getters, commit, dispatch, rootGetters }, { orderId }) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/updatestatepartiallycanceled/${orderId}`
          })
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              console.log(error.response.data)
              resolve(error.response.data)
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    changePositionState (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/executeOrder`

      return function ({ state, getters, commit, dispatch, rootGetters }, { orderId, basketItemid, canceled }) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/basketitemstate/${orderId}/${basketItemid}/${canceled ? 'canceled' : 'reactivate'}`
          })
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              console.log(error.response.data)
              resolve(error.response.data)
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    },
    changeQuantityPosition (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/executeOrder`

      return function ({ state, getters, commit, dispatch, rootGetters }, { orderId, basketItemid, configuration }) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/changequantity/${orderId}/${basketItemid}`,
            data: configuration
          })
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              console.log(error.response.data)
              resolve(error.response.data)
            })
            .finally(() => {
              commit('setLoading', { key: isKey, loading: false })
            })
        })
      }
    }
  })
}
