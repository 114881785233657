<template>
  <div id="Layout" data-layout-type="main">
    <layout-header class="layout-row row-static"/>

    <div class="layout-row row-shrink">
      <layout-navigation class="layout-col col-static"/>

      <main class="position-relative layout-col col-shrink layout-content layout-scroll">
        <slot/>
      </main>
    </div>

    <layout-footer class="layout-row row-static"/>
  </div>
</template>

<script>
import LayoutHeader from '@/components/layout/Header'
import LayoutNavigation from '@/components/layout/Navigation'
import LayoutFooter from '@/components/layout/Footer'

export default {
  name: 'LayoutMain',
  components: {
    LayoutHeader,
    LayoutNavigation,
    LayoutFooter
  }
}
</script>

<style lang="scss">
$layout-min-width: map-get($grid-breakpoints, 'lg') !default;

$layout-content-padding-y: $spacer * 2 !default;
$layout-content-padding-x: $spacer * 3 !default;

body {
  overflow: hidden;
  overflow-x: auto;
  min-width: $layout-min-width;
}

#Layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .layout-row {
    display: flex;
    overflow: hidden;
    width: 100%;

    &.row-static { flex: 0 0 auto; }
    &.row-shrink { flex: 0 1 100%; }
    &.row-grow { flex: 1 0 0%; }
  }

  .layout-col {
    height: 100%;

    &.col-static { flex: 0 0 auto; }
    &.col-shrink { flex: 0 1 100%; }
    &.col-grow { flex: 1 0 0%; }
  }

  .layout-scroll {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .layout-content {
    padding: $layout-content-padding-y $layout-content-padding-x;

    .img-banner {
      display: block;
      margin: 0 ($layout-content-padding-x * -1) $layout-content-padding-y;
      height: auto;
    }
  }
}
</style>
