import { pluginFactoryNoConfig } from 'bootstrap-vue/src/utils/plugins'

import { BIcon } from 'bootstrap-vue/src/icons/icon'
import { BIconstack } from 'bootstrap-vue/src/icons/iconstack'
import * as BootstrapIcons from 'bootstrap-vue/src/icons/icons'
import CustomIcons from './custom-icons'

const Icons = Object.assign(
  CustomIcons,
  Object.keys(BootstrapIcons).reduce((bIcons, iKey) => Object.assign(bIcons, { [iKey.replace(/^BIcon/, 'BIconB')]: BootstrapIcons[iKey] }), {})
)

export const iconNames = Object.keys(Icons)

export const iconNamesKebab = iconNames.map(name => name.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase().replace(/^-/g, ''))

export const normalizedIconNames = iconNamesKebab.map(n => n.replace(/^b-icon-/, ''))

export const IconsPlugin = pluginFactoryNoConfig({
  components: {
    BIcon,
    BIconstack,
    ...Icons
  }
})

export const BootstrapVueIcons = pluginFactoryNoConfig(
  { plugins: { IconsPlugin } },
  { NAME: 'BootstrapVueIcons' }
)
