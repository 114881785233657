import { instances } from '@/$plugins/http/core'
import auth from '../core'

const TOKEN_AUTH_KEY = auth.options.tokenDefaultKey

Object.values(instances)
  .forEach(i => {
    i.interceptors.request.use(
      config => {
        config.headers.Authorization = `Bearer ${auth.token(TOKEN_AUTH_KEY)}`
        return config
      },
      null
    )
  })
