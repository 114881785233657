import { TOASTER_OPTIONS } from '@/constants'

import Vue from 'vue'
import http from '@/$plugins/http'

import { normalize, unwrap } from '@/assets/js/helper/entity'

export default {
  state: {},
  getters: {
    getChangeRequest: state => changeRequestId => {
      const entity = normalize(state.entities.find(e => e.changeRequests.find(cR => cR.id === changeRequestId)))
      const changeRequest = entity.changeRequests.find(cR => cR.id === changeRequestId) || null

      return changeRequest ? Object.assign({ entityId: entity.value.id }, changeRequest) : null
    }
  },
  mutations: {},
  actions: {
    acceptChangeRequest (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/acceptChangeRequest`

      return function ({ state, getters, commit, dispatch }, changeRequestId = null) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/changerequests/${changeRequestId}/accept`
          })
            .then(response => {
              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.updated'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
              commit('setEntity', { key: entityKey, entity: response.data.result })
              resolve(getters.getEntity(unwrap(response.data.result).id))
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    },
    declineChangeRequest (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/declineChangeRequest`

      return function ({ state, getters, commit, dispatch }, changeRequestId = null) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/changerequests/${changeRequestId}/decline`
          })
            .then(response => {
              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.updated'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
              commit('setEntity', { key: entityKey, entity: response.data.result })
              resolve(getters.getEntity(unwrap(response.data.result).id))
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    },
    removeChangeRequest (serverModuleName = '', serverEntityName = '', entityKey = '') {
      const isKey = `${serverEntityName}/removeChangeRequest`

      return function ({ state, getters, commit, dispatch }, changeRequestId = null) {
        commit('setSending', { key: isKey, sending: true })

        return new Promise(resolve => {
          http({
            method: 'delete',
            url: `/${serverModuleName}/api/${serverEntityName}/changerequests/${changeRequestId}`
          })
            .then(response => {
              commit('setEntity', { key: entityKey, entity: response.data.result })
              resolve(getters.getEntity(unwrap(response.data.result).id))
              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.removed'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
            })
            .finally(() => {
              commit('setSending', { key: isKey, sending: false })
            })
        })
      }
    }
  }
}
