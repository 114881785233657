const DEV_COMPONENTS = {}
const COMPONENTS = {}

if (process.env.NODE_ENV === 'development') {
  DEV_COMPONENTS.DevVars = () => import(/* webpackChunkName "dev" */ '@/components/_dev/RootVariables')
  DEV_COMPONENTS.DevColors = () => import(/* webpackChunkName "dev" */ '@/components/_dev/Colors')
  DEV_COMPONENTS.DevIcons = () => import(/* webpackChunkName "dev" */ '@/components/_dev/Icons')
  DEV_COMPONENTS.DevText = () => import(/* webpackChunkName "dev" */ '@/components/_dev/Text')
  DEV_COMPONENTS.DevGrid = () => import(/* webpackChunkName "dev" */ '@/components/_dev/Grid')
  DEV_COMPONENTS.DevControls = () => import(/* webpackChunkName "dev" */ '@/components/_dev/Controls')
  DEV_COMPONENTS.DevComponents = () => import(/* webpackChunkName "dev" */ '@/components/_dev/Components')
}

// Layout (included in core)

// COMPONENTS.Header = () => import('@/components/layout/Header')

// Components (included on demand)

COMPONENTS.CControlValidator = () => import(/* webpackChunkName "controlvalidator" */ '@/components/form/ControlValidator')

COMPONENTS.CForm = () => import(/* webpackChunkName "form" */ '@/components/form/Form')

export default Object.assign({}, COMPONENTS, DEV_COMPONENTS)
