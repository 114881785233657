import { STOREMODULE_ENDPOINT_MAP, STOREMODULE_ENDPOINTMODULE_MAP } from '@/constants'

// import http from '@/$plugins/http'

import base from '../base'
import IS from '../base/is'
import MAINENTITY from '../base/entity-main'

export default {
  namespaced: true,
  state: base.createState(IS.state, MAINENTITY.state, { /* define custom state-properties */ }),
  getters: base.createGetters(IS.getters, MAINENTITY.getters, { /* define custom getters */ }),
  mutations: base.createMutations(IS.mutations, MAINENTITY.mutations, { /* define custom mutations */ }),
  actions: base.createEntityActions(STOREMODULE_ENDPOINTMODULE_MAP.customer, STOREMODULE_ENDPOINT_MAP.services, IS.actions, MAINENTITY.actions, { /* define custom actions */ })
}
