<script>
import { BREAKPOINTS } from '@/assets/js/modules/scss-variables'

const MOBILEFIRST_BREAKPOINT_MAP = Object.keys(BREAKPOINTS.mobilefirst.breakpoints).map(bKey => ({ key: bKey, value: BREAKPOINTS.mobilefirst.breakpoints[bKey] })).reverse()
const DESKTOPFIRST_BREAKPOINT_MAP = Object.keys(BREAKPOINTS.desktopfirst.breakpoints).map(bKey => ({ key: bKey, value: BREAKPOINTS.desktopfirst.breakpoints[bKey] }))

export default {
  data () {
    return {
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      page: {
        loaded: false,
        height: document.documentElement.scrollHeight,
        scrollTop: document.documentElement.scrollTop,
        scrollBottom: document.documentElement.scrollTop + window.innerHeight,
        scrollDirection: null,
        scrollingDown: false,
        scrollingUp: false
      },
      header: {
        height: null,
        inViewportHeight: null
      },
      // mobilefirst tablet { key, value } shorthand
      mt: BREAKPOINTS.mobilefirst.tablet,
      // mobilefirst desktop { key, value } shorthand
      md: BREAKPOINTS.mobilefirst.desktop,
      // desktopfirst tablet { key, value } shorthand
      dt: BREAKPOINTS.desktopfirst.tablet,
      // desktopfirst mobile { key, value } shorthand
      dm: BREAKPOINTS.desktopfirst.mobile
    }
  },
  computed: {
    mobilefirstBreakpoint () {
      return MOBILEFIRST_BREAKPOINT_MAP.find(b => b.value <= this.viewport.width)
    },
    desktopfirstBreakpoint () {
      return DESKTOPFIRST_BREAKPOINT_MAP.find(b => b.value > this.viewport.width)
    },
    isMobile () {
      return this.viewport.width < BREAKPOINTS.mobilefirst.tablet.value
    },
    isTablet () {
      return this.viewport.width >= BREAKPOINTS.mobilefirst.tablet.value && this.viewport.width < BREAKPOINTS.mobilefirst.desktop.value
    },
    isDesktop () {
      return this.viewport.width > BREAKPOINTS.desktopfirst.tablet.value
    }
  }
}
</script>
