<template>
  <header role="banner">
    <b-container fluid class="header-inner">
      <router-link class="logo-brand" to="/">
        <b-form-row class="align-items-center">
          <b-col cols="auto">
            <b-img block :src="`/images/wellvida_logo.png`" :alt="$t('brand.logo.alt')"/>
            <span class="sr-only">{{ $t('brand.logo.alt') }}</span>
          </b-col>

          <b-col>
            <div class="logo-title">{{ $t('brand.logo.title') }}</div>
            <div class="logo-subtitle">{{ $t('brand.logo.subtitle') }}</div>
          </b-col>
        </b-form-row>
      </router-link>

      <template v-if="$auth.check()">
        <nav class="navigation-meta">
          <b-row>
            <b-col cols="auto">
              <div class="form-group">
                <label>{{ $t('layout.header.navigation.loggedin.label') }}</label>
                <div class="form-control form-control-sm">{{ user.userName }}</div>
              </div>
            </b-col>
          </b-row>
        </nav>

        <div class="seperator"/>
      </template>

      <b-link class="logo-bambit" :href="$t('layout.header.logo.bambit.href')" target="_blank">
        <b-img block src="/images/logo-buzzle.png" :alt="$t('layout.header.logo.bambit.alt')"/>
        <span class="sr-only">{{ $t('layout.header.logo.bambit.alt') }}</span>
      </b-link>
    </b-container>
  </header>
</template>

<script>
export default {
  name: 'HeaderMain',
  computed: {
    user () {
      return this.$store.getters['profile/get']
    }
  }
}
</script>

<style lang="scss">
$header-height: 60px !default;
$header-bg: $white !default;
$header-box-shadow: 0 0 4px 0 rgba($black, 0.15) !default;

$header-logo-brand-max-height: 30px !default;

$header-logo-brand-title-font-family: inherit !default;
$header-logo-brand-title-font-weight: $font-weight-bold !default;
$header-logo-brand-title-font-size: inherit !default;
$header-logo-brand-title-color: inherit !default;
$header-logo-brand-title-line-height: 1 !default;

$header-logo-brand-subtitle-font-family: inherit !default;
$header-logo-brand-subtitle-font-weight: inherit !default;
$header-logo-brand-subtitle-font-size: $font-size-xs !default;
$header-logo-brand-subtitle-color: inherit !default;
$header-logo-brand-subtitle-line-height: 1.4 !default;

$header-logo-bambit-max-height: 40px !default;

header[role="banner"] {
  box-shadow: $header-box-shadow;

  .header-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: $header-height;
    background-color: $header-bg;

    .seperator {
      margin-left: $spacer;
      margin-right: $spacer;
      height: 100%;
      border-left: $border-width solid $border-color;
    }

    .logo-brand {
      display: flex;
      align-items: center;
      margin-right: auto;
      color: inherit;
      text-decoration: none;

      img {
        height: $header-height;
        max-height: $header-logo-brand-max-height;
      }

      .logo-title {
        font-family: $header-logo-brand-title-font-family;
        font-weight: $header-logo-brand-title-font-weight;
        font-size: $header-logo-brand-title-font-size;
        color: $header-logo-brand-title-color;
        line-height: $header-logo-brand-title-line-height;
      }

      .logo-subtitle {
        font-family: $header-logo-brand-subtitle-font-family;
        font-weight: $header-logo-brand-subtitle-font-weight;
        font-size: $header-logo-brand-subtitle-font-size;
        color: $header-logo-brand-subtitle-color;
        line-height: $header-logo-brand-subtitle-line-height;
      }
    }

    .logo-bambit {
      display: block;

      img {
        height: 100px;
        max-height: $header-logo-bambit-max-height;
      }
    }

    .navigation-meta {
      .form-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: $form-group-margin-bottom * 0.25;

        label {
          margin-bottom: 0;
          margin-right: $label-margin-bottom;
          text-align: right;
          white-space: nowrap;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
