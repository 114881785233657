import { TOASTER_OPTIONS } from '@/constants'

import Vue from 'vue'
import http from '@/$plugins/http'

export default {
  state: {
    notes: []
  },
  getters: {
    getNotes: state => state.notes,
    getNote: state => noteId => state.notes.find(n => n.id === noteId)
  },
  mutations: {
    setNotes (state, notes = []) {
      state.notes = notes
    },
    setNote (state, note = {}) {
      const updateIndex = state.notes.findIndex(n => n.id === note.id)
      const newIndex = state.notes.length

      Vue.set(state.notes, updateIndex >= 0 ? updateIndex : newIndex, note)
    },
    removeNote (state, note = {}) {
      const removeIndex = state.notes.findIndex(n => n.id === note.id)

      Vue.delete(state.notes, removeIndex)
    }
  },
  actions: {
    getNotes (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, entityId = '') {
        commit('setLoading', { key: 'getNotes', loading: true, initial: true })

        return new Promise(resolve => {
          http({
            method: 'get',
            url: `/${serverModuleName}/api/${serverEntityName}/${entityId}/notes`
          })
            .then(response => {
              commit('setNotes', response.data.result)
              resolve(getters.getNotes)
            })
            .finally(() => {
              commit('setLoading', { key: 'getNotes', loading: false })
            })
        })
      }
    },
    createNote (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        commit('setSending', { key: 'createNote', sending: false })

        return new Promise(resolve => {
          http({
            method: 'put',
            url: `/${serverModuleName}/api/${serverEntityName}/${options.entityId}/notes`,
            data: options.note
          })
            .then(response => {
              // TODO: switch to one note answer, if backend switches
              // commit('setNote', response.data.result.value)
              // resolve(getters.getNote(response.data.result.value.id))
              commit('setNotes', response.data.result)
              resolve(getters.getNotes)
              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.created'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
            })
            .finally(() => {
              commit('setSending', { key: 'createNote', sending: false })
            })
        })
      }
    },
    removeNote (serverModuleName = '', serverEntityName = '', entityKey = '') {
      return function ({ state, getters, commit, dispatch }, options = {}) {
        commit('setSending', { key: 'removeNote', sending: true })

        return new Promise(resolve => {
          http({
            method: 'delete',
            url: `/${serverModuleName}/api/${serverEntityName}/${options.entityId}/note/${options.note.id}`
          })
            .then(response => {
              commit('removeNote', options.note)
              resolve()
              if (Vue.$app.$bvToast) Vue.$app.$bvToast.toast(Vue.$app.$i18n.t('entity.apiactions.removed'), Object.assign({}, TOASTER_OPTIONS.api.options.send, { title: `API ${serverEntityName}` }))
            })
            .finally(() => {
              commit('setSending', { key: 'removeNote', sending: false })
            })
        })
      }
    }
  }
}
