export function getSearchParameters (key = null) {
  const parameters = decodeURIComponent(window.location.search)
    .replace(/^\?/, '')
    .split('&')
    .filter(p => p)
    .map(p => {
      const split = p.split('=')
      return { key: split[0] || '', value: split[1] || '' }
    })

  if (parameters.map(p => p.key).includes(key)) return parameters.find(p => p.key === key).value
  else if (key !== null) return null
  else return parameters
}
