import Router from '../index.js'
import Progress from 'nprogress'

// Doc: https://www.npmjs.com/package/nprogress
Progress.configure({
  template: '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
  minimum: 0.08,
  easing: 'ease',
  speed: 200,
  trickle: true,
  trickleRate: 0.02,
  trickleSpeed: 800,
  showSpinner: false,
  parent: 'body'
})

Router.beforeEach((to, from, next) => {
  Progress.start()
  next()
})

Router.afterEach((to, from) => {
  Progress.done()
})
