import { INITIAL_LANGUAGE, AVAILABLE_LANGUAGES, AVAILABLE_DATE_I18N, setLanguage, getIsoCode } from '@/$plugins/i18n/languages'

import base from '../base'
import IS from '../base/is'

const INITIAL_ISOCODE = getIsoCode(INITIAL_LANGUAGE)

export default {
  namespaced: true,
  state: base.createState(IS.state, {
    language: {
      active: INITIAL_LANGUAGE,
      available: AVAILABLE_LANGUAGES,
      dateLanguage: AVAILABLE_DATE_I18N[INITIAL_ISOCODE],
      dateLanguageAvailable: AVAILABLE_DATE_I18N
    },
    datetime: {
      isoCode: AVAILABLE_DATE_I18N[INITIAL_ISOCODE].code,
      weekStartsOn: AVAILABLE_DATE_I18N[INITIAL_ISOCODE].options.weekStartsOn
    },
    searchEntityKey: null
  }),
  getters: base.createGetters(IS.getters, {
    getLanguage: state => state.language.active,
    getLooseLanguage: state => getIsoCode(state.language.active),
    getLanguagesAvailable: state => state.language.available,
    getDateLanguage: state => state.language.dateLanguage,
    getDateLanguageAvailable: state => state.language.dateLanguageAvailable,

    getDatetimeIsoCode: state => state.datetime.isoCode,
    getDatetimeWeekStartsOn: state => state.datetime.weekStartsOn,
    getDatetimeIsoCodeOptions: state => language => state.language.dateLanguageAvailable[getIsoCode(language)],

    getSearchEntityKey: state => state.searchEntityKey
  }),
  mutations: base.createMutations(IS.mutations, {
    setLanguage (state, language = '') {
      state.language.active = setLanguage(language)
      state.language.dateLanguage = state.dateLanguageAvailable[getIsoCode(state.language.active)]
      state.datetime.isoCode = state.dateLanguageAvailable[state.language.dateLanguage].code
      state.datetime.weekStartsOn = state.dateLanguageAvailable[state.language.dateLanguage].options.weekStartsOn
    },
    setSearchEntityKey (state, key = null) {
      state.searchEntityKey = key
    }
  }),
  actions: base.createActions(IS.actions, {})
}
